import Carousel from 'react-bootstrap/Carousel';
import {Event} from "../../../Helper";
import React from "react";
import CrasherHour from "./includes/CrasherHour";
import ReferrerWeekly from "./includes/ReferrerWeekly";

function showBannerAffiliate() {
    Event.emit('show_banner_affiliate');
}

function showBannerCrasher() {
    Event.emit('show_banner_crasher');
}

function BannerCarousal() {

    return (
        <Carousel>
            <Carousel.Item onClick={showBannerAffiliate}>
                <img className={"d-block w-100"} style={{borderRadius: '6px 6px 0px 0px'}} src={"/assets/images/avepic_top_banner.png"} alt="Affiliate"/>
                <div className={'d-block w-100 bg-image-custom'} style={{borderRadius: '0px 0px 6px 6px', fontSize: '0.8em', paddingLeft: '5px'}}><ReferrerWeekly/></div>
            </Carousel.Item>
            <Carousel.Item onClick={showBannerCrasher}>
                <img style={{borderRadius: '6px 6px 0px 0px'}} className="d-block w-100" src={"/assets/images/avepic_top_banner_crasher.png"} alt="Crasher"/>
                <div className={'d-block w-100 bg-image-custom'} style={{borderRadius: '0px 0px 6px 6px', fontSize: '0.8em', paddingLeft: '5px'}}><CrasherHour/></div>
            </Carousel.Item>
        </Carousel>
    );
}

export default BannerCarousal;